import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD-8t86sk26jHw8nTjCbFRxJO8z_QIbRJc",
    authDomain: "siendonerds-246de.firebaseapp.com",
    projectId: "siendonerds-246de",
    storageBucket: "siendonerds-246de.appspot.com",
    messagingSenderId: "649082795804",
    appId: "1:649082795804:web:40767190a918d73bd62cd9",
    measurementId: "G-1EXE931GD0",
  };
  

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

createApp(App).use(router).mount('#app')
